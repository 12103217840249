import * as React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { getLanguage } from "../getLanguage";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Redirect } from "@reach/router";

interface IContactPageTemplateProps {
  title;
  subTitle;
  description;
  basicSection;
  optionalSection;
  disclaimer;
  sendButtonText;
  emailValidationErrorMessage;
  firstNameValidationErrorMessage;
  lastNameValidationErrorMessage;
  topicValidationErrorMessage;
}

export const ContactPageTemplate = (props: IContactPageTemplateProps) => {
  const lang = getLanguage();
  return (
    <Layout deUrl="/de/kontakt" enUrl="/en/contact">
      <Helmet title={props.title}>
        <meta name="description" content={props.description} />
      </Helmet>
      <section className="first">
        <h1>{props.title}</h1>
        <p>
          <div dangerouslySetInnerHTML={{ __html: props.subTitle }} />{" "}
        </p>
      </section>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          company: "",
          phoneNumber: "",
          email: "",
          topic: "",
          preferredContactMethod: "phone",
          submitted: false
        }}
        onSubmit={(values, { setSubmitting, setValues }) => {
          setSubmitting(true);
          const encode = data => {
            return Object.keys(data)
              .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
              .join("&");
          };
          const axiosConfig = {
            header: { "Content-Type": "application/x-www-form-urlencoded" }
          };
          axios
            .post(
              "/",
              encode({
                "form-name": "contact",
                ...values
              }),
              axiosConfig as any
            )
            .then(() => setValues(Object.assign(values, { submitted: true })))
            .catch(error => setSubmitting(false));
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(props.firstNameValidationErrorMessage),
          lastName: Yup.string().required(props.lastNameValidationErrorMessage),
          topic: Yup.string().required(props.topicValidationErrorMessage),
          email: Yup.string()
            .email(props.emailValidationErrorMessage)
            .required(props.emailValidationErrorMessage)
        })}
      >
        {formProps => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset
          } = formProps;
          const { submitted } = values;
          return submitted ? (
            <Redirect to={lang === "en" ? "/en/thank-you" : "/de/vielen-dank"} noThrow />
          ) : (
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <section className="contact">
                <div>
                  <h2>{props.basicSection.title}</h2>
                  <p>{props.basicSection.subTitle}</p>
                </div>
                <div>
                  <div>
                    <label htmlFor="firstName">{props.basicSection.firstName}</label>
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={values.firstName}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.firstName && touched.firstName ? "text-input-error" : ""}
                    />
                    {errors.firstName && touched.firstName && <div className="input-feedback">{errors.firstName}</div>}
                  </div>
                  <div>
                    <label htmlFor="lastName">{props.basicSection.lastName}</label>
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={values.lastName}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.lastName && touched.lastName ? "text-input-error" : ""}
                    />
                    {errors.lastName && touched.lastName && <div className="input-feedback">{errors.lastName}</div>}
                  </div>
                  <div className="full">
                    <label htmlFor="company">{props.basicSection.company}</label>
                    <input
                      id="company"
                      name="company"
                      type="text"
                      value={values.company}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="full">
                    <label htmlFor="phoneNumber">{props.basicSection.phoneNumber}</label>
                    <input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      value={values.phoneNumber}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="full">
                    <label htmlFor="email">{props.basicSection.email}</label>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      value={values.email}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.email && touched.email ? "text-input-error" : ""}
                    />
                    {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
                  </div>
                </div>
              </section>
              <section className="contact">
                <div>
                  <h2>{props.optionalSection.title}</h2>
                  <p>{props.optionalSection.subTitle}</p>
                </div>
                <div>
                  <div className="full">
                    <label htmlFor="topic">{props.optionalSection.topic}</label>
                    <textarea
                      id="topic"
                      name="topic"
                      value={values.topic}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.topic && touched.topic ? "text-input-error" : ""}
                    />
                    {errors.topic && touched.topic && <div className="input-feedback">{errors.topic}</div>}
                  </div>
                  <div className="full">
                    <label>{props.optionalSection.preferredContactMethod}</label>
                    <br />
                    <div>
                      <label className="radio">
                        <input
                          onChange={handleChange}
                          type="radio"
                          name="preferredContactMethod"
                          value="phone"
                          disabled={isSubmitting}
                          checked={values.preferredContactMethod === "phone"}
                        />
                        <i />
                        <span>{props.optionalSection.phone}</span>
                      </label>
                      <label className="radio">
                        <input
                          onChange={handleChange}
                          type="radio"
                          name="preferredContactMethod"
                          checked={values.preferredContactMethod === "email"}
                          value="email"
                          disabled={isSubmitting}
                        />
                        <i />
                        <span>{props.optionalSection.email}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </section>
              <section className="contact">
                <div style={{ gridColumn: "1/3" }}>
                  <div className="full">
                    <div className="right">
                      <button disabled={isSubmitting} className="send-button" type="submit">
                        {props.sendButtonText}
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <p className="disclaimer">{props.disclaimer}</p>
              <div data-netlify-recaptcha />
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

interface IContactPageProps {
  data;
}

export default (props: IContactPageProps) => {
  const { markdownRemark: post } = props.data;

  return (
    <ContactPageTemplate
      title={post.frontmatter.title}
      subTitle={post.frontmatter.subTitle}
      description={post.frontmatter.description}
      basicSection={post.frontmatter.basicSection}
      optionalSection={post.frontmatter.optionalSection}
      disclaimer={post.frontmatter.disclaimer}
      sendButtonText={post.frontmatter.sendButtonText}
      emailValidationErrorMessage={post.frontmatter.emailValidationErrorMessage}
      firstNameValidationErrorMessage={post.frontmatter.firstNameValidationErrorMessage}
      lastNameValidationErrorMessage={post.frontmatter.lastNameValidationErrorMessage}
      topicValidationErrorMessage={post.frontmatter.topicValidationErrorMessage}
    />
  );
};

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        subTitle
        description
        basicSection {
          title
          subTitle
          firstName
          lastName
          company
          phoneNumber
          email
        }
        optionalSection {
          title
          subTitle
          topic
          preferredContactMethod
          phone
          email
        }
        disclaimer
        sendButtonText
        emailValidationErrorMessage
        firstNameValidationErrorMessage
        lastNameValidationErrorMessage
        topicValidationErrorMessage
      }
    }
  }
`;
